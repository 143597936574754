<template>
  <div
    id="cover"
    class="te-cover-bg overflow-hidden"
  >
    <div class="co-container-lg relative p-4 md:p-5 lg:py-5 xl:px-0">
      <div class="te-cover-grid">
        <!-- 1段目 -->
        <div class="te-fade-in te-cover-box-1 te-bg-cover-item te-aspect-squere te-square-shadow te-bg-cover-first relative hidden lg:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/title-mentor.svg"
            width="42"
            height="13"
            alt="Mentor"
            class="absolute left-3 top-3"
          >

          <div class="flex h-full items-center justify-center">
            <img
              src="~/assets/images/Te/TeTop/Cover/Consultation/icon-mentor.svg"
              width="76"
              height="62"
              alt="Mentor"
              class="mx-auto"
            >
          </div>
        </div>
        <div class="te-cover-box-2 te-bg-cover-item te-rectangle-shadow px-4 py-3 md:p-5">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/block-yuko.webp"
            width="187"
            height="79"
            alt="楽しい、やりたいを折れさせない"
            class="te-text-min-auto mx-auto w-full"
          >
        </div>
        <div class="te-cover-box-3 te-bg-cover-item te-aspect-squere te-square-shadow te-bg-cover-first hidden px-4 py-3 md:p-5 sm:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-achieve-goals.svg"
            width="124"
            height="124"
            alt="やりたいを実現する人を増やしたい"
          >
        </div>
        <div class="te-cover-box-4 te-bg-cover-item te-rectangle-shadow px-4 py-3 md:p-5">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/block-utsunomiya.webp"
            width="187"
            height="79"
            alt="喜びは学びにのきっかけになる"
            class="mx-auto w-full"
          >
        </div>
        <div class="te-cover-box-5 te-bg-cover-item te-aspect-squere te-bg-cover-first te-square-long-shadow relative hidden lg:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/title-growth.svg"
            width="42"
            height="13"
            alt="Growth"
            class="absolute left-3 top-3"
          >
          <div class="flex h-full items-center justify-center">
            <img
              src="~/assets/images/Te/TeTop/Cover/Consultation/icon-growth.svg"
              width="65"
              height="65"
              alt="Growth"
              class="mx-auto"
            >
          </div>
        </div>
        <!-- 2段目 -->
        <div class="te-cover-box-6 te-bg-cover-item te-aspect-squere te-square-shadow te-bg-cover-second hidden p-5 lg:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-counseling.svg"
            width="124"
            height="124"
            alt="メンターに相談できる環境"
          >
        </div>
        <div class="te-cover-box-7 te-bg-cover-item te-aspect-squere te-square-shadow px-4 py-3 md:p-5">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-cuddle.svg"
            width="124"
            height="124"
            alt="寄りそうことで学習の継続をサポート"
          >
        </div>
        <div class="te-cover-box-8 te-bg-cover-item te-aspect-squere te-square-shadow te-bg-cover-third h-full hidden p-5 lg:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-high-quality.svg"
            width="124"
            height="124"
            alt="高品質な学習をだれでもどこでも"
          >
        </div>
        <div class="te-cover-box-9 te-bg-cover-item te-aspect-squere te-square-shadow px-4 py-3 md:p-5">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-concentration.svg"
            width="124"
            height="124"
            alt="学びに集中できる仕組み"
          >
        </div>
        <!-- テックくん -->
        <div class="te-cover-box-10 te-rectangle-main-shadow flex flex-col justify-between">
          <div class="te-tech-eye-wrap relative text-center">
            <img
              src="~/assets/images/Te/TeTop/Cover/Consultation/tech-kun-eye.svg"
              width="115"
              height="36"
              class="-mt-3 inline"
            >
          </div>
          <div class="px-5">
            <img
              class="te-text-min-auto w-full"
              src="~/assets/images/Te/TeTop/Cover/Consultation/text-tech-kun-top.svg"
              width="285"
              height="115"
              alt="テックアカデミーは「学び力」を提供し続けます。"
            >
          </div>
          <div class="w-full px-5 pb-3 sm:px-6 sm:pb-4 md:px-10 lg:px-6">
            <img
              class="te-text-min-auto mx-auto w-full"
              src="~/assets/images/Te/TeTop/Cover/Consultation/text-tech-kun-bottom.svg"
              width="297"
              height="22"
              alt="てくてく学び続けられるオンラインスクール テックアカデミー"
            >
          </div>
        </div>
        <div class="te-cover-box-11 te-bg-cover-item te-aspect-squere te-square-shadow px-4 py-3 md:p-5">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-hot-heart.svg"
            width="124"
            height="124"
            alt="熱い心を埋もれたままにしないで"
          >
        </div>
        <div class="te-cover-box-12 hidden lg:block">
          <div class="te-mini-grid te-aspect-squere h-full">
            <!-- メンターアイコン -->
            <div class="te-mini-grid-1 te-bg-cover-mini-item te-aspect-squere te-square-mini-shadow te-bg-cover-second relative">
              <div class="flex size-full items-center justify-center">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/icon-mentor.svg"
                  width="34"
                  height="46"
                  alt="アイコン"
                >
              </div>
              <div class="absolute bottom-2 w-full">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/title-mentor.svg"
                  width="27"
                  height="8"
                  alt="Mentor"
                  class="mx-auto"
                >
              </div>
            </div>
            <!-- カリキュラムアイコン -->
            <div class="te-mini-grid-2 te-bg-cover-mini-item te-aspect-squere te-square-mini-shadow te-bg-cover-second relative">
              <div class="flex size-full items-center justify-center">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/icon-curriculum.svg"
                  width="21"
                  height="28"
                  alt="アイコン"
                >
              </div>
              <div class="absolute bottom-2 w-full">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/title-curriculum.svg"
                  width="43"
                  height="8"
                  alt="Curriculum"
                  class="mx-auto"
                >
              </div>
            </div>
            <!-- グロースアイコン -->
            <div class="te-mini-grid-3 te-bg-cover-mini-item te-aspect-squere te-square-long-shadow te-bg-cover-second relative">
              <div class="flex size-full items-center justify-center">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/icon-growth.svg"
                  width="28"
                  height="28"
                  alt="アイコン"
                >
              </div>
              <div class="absolute bottom-2 w-full">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/title-growth.svg"
                  width="27"
                  height="8"
                  alt="Mentor"
                  class="mx-auto"
                >
              </div>
            </div>
            <!-- チャンスアイコン -->
            <div class="te-mini-grid-4 te-bg-cover-mini-item te-aspect-squere te-square-long-shadow te-bg-cover-second relative">
              <div class="flex size-full items-center justify-center">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/icon-chance.svg"
                  width="25"
                  height="25"
                  alt="アイコン"
                >
              </div>
              <div class="absolute bottom-2 w-full">
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/title-chance.svg"
                  width="27"
                  height="8"
                  alt="Chance"
                  class="mx-auto"
                >
              </div>
            </div>
          </div>
        </div>
        <!-- CTA -->
        <client-only>
          <div
            v-if="!paid && !appliedHtmlcssTrial"
            class="te-cover-box-13 te-aspect-squere"
          >
            <div class="te-squeare-btn">
              <a
                href="/counseling/htmlcss-schedule"
                class="bot_open te-cover-cta block size-full"
              >
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/text-cta.svg"
                  width="104"
                  height="104"
                  alt="無料で相談してみる"
                  class="te-text-min-auto mx-auto w-full"
                >
              </a>
            </div>
          </div>
          <!-- すでに相談申し込み済 -->
          <div
            v-if="paid || appliedHtmlcssTrial"
            class="te-cover-box-13 te-aspect-squere"
          >
            <div class="te-squeare-btn">
              <a
                href="/course"
                class="te-cover-cta block size-full"
              >
                <img
                  src="~/assets/images/Te/TeTop/Cover/Consultation/text-course-cta.svg"
                  width="104"
                  height="104"
                  alt="コース紹介を見る"
                  class="te-text-min-auto mx-auto w-full"
                >
              </a>
            </div>
          </div>
        </client-only>
        <!-- 一歩踏み出す -->
        <div class="te-cover-box-14 te-bg-cover-item te-aspect-squere te-square-shadow te-bg-cover-third te-square-long-shadow hidden p-5 lg:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-step.svg"
            width="124"
            height="124"
            alt="一歩踏み出すを躊躇しているあなたへ"
          >
        </div>
        <!-- 3段目 -->
        <!-- カリキュラム -->
        <div class="te-cover-box-15 te-bg-cover-item te-aspect-squere te-square-shadow te-bg-cover-fourth relative hidden lg:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/title-curriculum.svg"
            width="67"
            height="8"
            alt="Curriculum"
            class="absolute left-3 top-3"
          >

          <div class="flex h-full items-center justify-center">
            <img
              src="~/assets/images/Te/TeTop/Cover/Consultation/icon-curriculum.svg"
              width="51"
              height="67"
              alt="Curriculum"
              class="mx-auto"
            >
          </div>
        </div>
        <!-- アザミさん -->
        <div class="te-cover-box-16 te-bg-cover-item te-rectangle-shadow te-bg-cover-fourth hidden px-4 py-3 sm:block md:p-5">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/block-azami.webp"
            width="187"
            height="79"
            alt="160時間学べば、必ずスタートラインに立てる"
            class="mx-auto w-full"
          >
        </div>
        <!-- 仕事を受注し -->
        <div class="te-cover-box-17 te-bg-cover-item te-aspect-squere te-square-shadow te-bg-cover-fourth px-4 py-3 md:p-5">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/text-work.svg"
            width="124"
            height="124"
            alt="仕事を受注し終えられるまで伴奏"
          >
        </div>
        <!-- たぬまさん -->
        <div class="te-cover-box-18 te-bg-cover-item te-rectangle-shadow te-bg-cover-fourth hidden p-5 sm:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/block-tanuma.webp"
            width="187"
            height="79"
            alt="人生のターニングポイントこそ、妥協なき支援を"
            class="mx-auto w-full"
          >
        </div>
        <!-- チャンス -->
        <div class="te-cover-box-19 te-bg-cover-item te-aspect-squere te-bg-cover-fourth te-square-long-shadow relative hidden lg:block">
          <img
            src="~/assets/images/Te/TeTop/Cover/Consultation/title-chance.svg"
            width="42"
            height="8"
            alt="Chance"
            class="absolute left-3 top-3"
          >

          <div class="flex h-full items-center justify-center">
            <img
              src="~/assets/images/Te/TeTop/Cover/Consultation/icon-chance.svg"
              width="57"
              height="57"
              alt="Chance"
              class="mx-auto"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useUser } from '~/store/user'

import TeCommonButtonLabel from '~/components/Te/TeCommon/Button/TeCommonButtonLabel/TeCommonButtonLabel.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight, faAngleRight } from '@awesome.me/kit-65d95819ce/icons/classic/solid'
import { faCircleO } from '@awesome.me/kit-65d95819ce/icons/classic/regular'

const options = {
  async setup() {
    const { appliedHtmlcssTrial, paid } = storeToRefs(useUser())

    return {
      appliedHtmlcssTrial,
      paid,
      faChevronRight,
      faCircleO,
      faAngleRight,
    }
  },
  components: {
    TeCommonButtonLabel,
    FontAwesomeIcon,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
/* 背景 */
.te-cover-bg {
  position: relative;
  z-index: 0;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-dot.png'),
    linear-gradient(180deg, #0ad3ca 0%, #068de1 100%);
  background-repeat: repeat;
}

/* グリッド */
.te-cover-grid {
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

@media (min-width: 576px) {
  .te-cover-grid {
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 992px) {
  .te-cover-grid {
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 16px;
  }
}

/* ブロック */
.te-bg-cover-item {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #fff;
  border-radius: 1.25rem;
}

/* アスペクト比 */
.te-aspect-squere {
  /* aspect-ratio: 1; */
}

/* 短い影 */
.te-square-shadow {
  position: relative;

}

/* 短い影の表現 */
.te-square-shadow::after {
  position: absolute;
  top: -0.5rem;
  left: 0;
  z-index: -2;
  display: block;
  width: calc(100% - .7rem);
  height: 100%;
  content: '';
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  transform: translateX(4%) translateY(54%) skewY(-46deg) skewX(25deg)
    scaleY(1);
  transform-origin: 50% 0;
  animation-name: fadein;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  @media (min-width: 576px) {
    width: calc(100% - .3rem);
    transform: translateX(4%) translateY(54%) skewY(-43deg) skewX(25deg)
    scaleY(1);
  }
  @media (min-width: 992px) {
    width: calc(100% - .62rem);
    transform: translateX(4%) translateY(54%) skewY(-46deg) skewX(25deg)
    scaleY(1);
  }
  @media (min-width: 1200px) {
    width: calc(100% - .7rem);
  }
}

@keyframes fadein {
  0% {
     transform: translateX(4%) translateY(54%) skewY(-46deg) skewX(25deg)
    scaleY(0);
  }
  100% {
     transform: translateX(4%) translateY(54%) skewY(-46deg) skewX(25deg)
    scaleY(1);
  }
}

@media (min-width: 576px) {
  @keyframes fadein {
    0% {
      transform: translateX(4%) translateY(54%) skewY(-43deg) skewX(25deg)
      scaleY(0);
    }
    100% {
      transform: translateX(4%) translateY(54%) skewY(-43deg) skewX(25deg)
      scaleY(1);
    }
  }
}

@media (min-width: 992px) {
  @keyframes fadein {
    0% {
      transform: translateX(4%) translateY(54%) skewY(-46deg) skewX(25deg)
      scaleY(0);
    }
    100% {
      transform: translateX(4%) translateY(54%) skewY(-46deg) skewX(25deg)
      scaleY(1);
    }
  }
}

.te-square-mini-shadow {
  position: relative;
}

.te-square-mini-shadow::after {
  position: absolute;
  top: -0.5rem;
  left: 0;
  z-index: -2;
  display: block;
  width: calc(100% - .55rem);
  height: 100%;
  content: '';
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  transform: translateX(4%) translateY(54%) skewY(-47deg) skewX(25deg)
    scaleY(1);
  transform-origin: 50% 0;
  animation-name: fademini;
  animation-duration: 3s;
  animation-timing-function: ease-out;
}

@media (min-width: 1200px) {
  .te-square-mini-shadow::after {
    transform: translateX(4%) translateY(54%) skewY(-45deg) skewX(25deg)
      scaleY(1);
  }
}

@keyframes fademini {
  0% {
    transform: translateX(4%) translateY(54%) skewY(-47deg) skewX(25deg)
    scaleY(0);
  }
  100% {
    transform: translateX(4%) translateY(54%) skewY(-47deg) skewX(25deg)
    scaleY(1);
  }
}

@media (min-width: 1200px) {
  @keyframes fademini {
    0% {
    transform: translateX(4%) translateY(54%) skewY(-45deg) skewX(25deg)
    scaleY(0);
  }
  100% {
    transform: translateX(4%) translateY(54%) skewY(-45deg) skewX(25deg)
    scaleY(1);
  }
  }
}

/* 1段目の背景 */
.te-bg-cover-first {
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-one.jpg');
  background-repeat: repeat-x;
  background-size: contain;
}

/* 2段目の背景 */
.te-bg-cover-second {
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-two.jpg');
  background-repeat: repeat-x;
  background-size: contain;
}

/* 3段目の背景 */
.te-bg-cover-third {
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-three.jpg');
  background-repeat: repeat-x;
  background-size: contain;
}

/* 4段目の背景 */
.te-bg-cover-fourth {
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-four.jpg');
  background-repeat: repeat-x;
  background-size: contain;
}

/* 長い影 */
.te-square-long-shadow {
  position: relative;
}

/* 長い影の表現 */
.te-square-long-shadow::after {
  position: absolute;
  top: -0.5rem;
  left: 0;
  z-index: -2;
  display: block;
  width: calc(100% - 1rem);
  height: 100%;
  content: '';
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  transform: translateX(4%) translateY(54%) skewY(-47deg) skewX(25deg)
    scaleY(4);
  transform-origin: 50% 0;
  animation-name: fadelong;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  @media (min-width: 992px) {
    width: calc(100% - .65rem);
  }
  @media (min-width: 1200px) {
    width: calc(100% - .74rem);
  }
}

@keyframes fadelong {
  0% {
    transform: translateX(4%) translateY(54%) skewY(-47deg) skewX(25deg)
    scaleY(0);
  }
  100% {
    transform: translateX(4%) translateY(54%) skewY(-47deg) skewX(25deg)
    scaleY(4);
  }
}

/* 横長の影 */
.te-rectangle-shadow {
  position: relative;
}

/* 横長の影の表現 */
.te-rectangle-shadow::after {
  position: absolute;
  top: 0;
  left: 0.75rem;
  z-index: -1;
  display: block;
  width: calc(100% - 1.2rem);
  height: 100%;
  content: '';
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  transform: translateY(50%) skewY(-25deg) skewX(32deg) scaleY(1);
  transform-origin: 50% 0;
  animation-name: faderectangle;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  @media (min-width: 576px) {
    left: .3rem;
    width: calc(100% - .5rem);
  }
}

@keyframes faderectangle {
  0% {
    transform: translateY(50%) skewY(-25deg) skewX(32deg) scaleY(0);
  }
  100% {
    transform: translateY(50%) skewY(-25deg) skewX(32deg) scaleY(1);
  }
}

/* テックくんの影 */
.te-rectangle-main-shadow {
  position: relative;
}

/* テックくんの影の表現 */
.te-rectangle-main-shadow::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  transform: translateY(50%) skewY(-30deg) skewX(30deg) scaleY(1);
  transform-origin: 50% 0;
  animation-name: fadetech;
  animation-duration: 3s;
  animation-timing-function: ease-out;
  @media (min-width: 576px) {
    transform: translateY(50%) skewY(-33deg) skewX(33deg) scaleY(1);
  }
  @media (min-width: 992px) {
    transform: translateY(50%) skewY(-33deg) skewX(28deg) scaleY(1);
  }
  @media (min-width: 1200px) {
    transform: translateY(50%) skewY(-31deg) skewX(28deg) scaleY(1);
  }
}

@keyframes fadetech {
    0% {
      transform: translateY(50%) skewY(-30deg) skewX(30deg) scaleY(0);
    }
    100% {
      transform: translateY(50%) skewY(-30deg) skewX(30deg) scaleY(1);
    }
  }

@media (min-width: 576px) {
  @keyframes fadetech {
    0% {
      transform: translateY(50%) skewY(-33deg) skewX(33deg) scaleY(0);
    }
    100% {
      transform: translateY(50%) skewY(-33deg) skewX(33deg) scaleY(1);
    }
  }
}

@media (min-width: 992px) {
  @keyframes fadetech {
    0% {
      transform: translateY(50%) skewY(-33deg) skewX(28deg) scaleY(0);
    }
    100% {
      transform: translateY(50%) skewY(-33deg) skewX(28deg) scaleY(1);
    }
  }
}

@media (min-width: 1200px) {
  @keyframes fadetech {
    0% {
      transform: translateY(50%) skewY(-31deg) skewX(28deg) scaleY(0);
    }
    100% {
      transform: translateY(50%) skewY(-31deg) skewX(28deg) scaleY(1);
    }
  }
}

.te-cover-annotation {
  width: 19.437rem;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.te-text-counseling {
  color: #13cd8a;
}

.te-cover-icon {
  margin-top: 0.125rem;
  color: #13cd8a;
}

/* 1段目 */
.te-cover-box-1 {
  grid-area: 1 / 1 / 2 / 2;
}

/* 楽しいをやりたい */
.te-cover-box-2 {
  grid-area: 3 / 1 / 4 / 3;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-three.jpg');
  background-repeat: repeat-x;
  background-size: contain;
  @media (min-width: 576px) {
    grid-area: 1 / 1 / 2 / 3;
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-one.jpg');
  }
  @media (min-width: 992px) {
    grid-area: 1 / 2 / 2 / 4;
  }
}

/* きらめきを最大化 */
.te-cover-box-3 {
  @media (min-width: 576px) {
    grid-area: 1 / 3 / 2 / 4;
  }
  @media (min-width: 992px) {
    grid-area: 1 / 4 / 2 / 5;
  }
}

/* 学びは喜び */
.te-cover-box-4 {
  grid-area: 4 / 2 / 5 / 4;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-three.jpg');
  background-repeat: repeat-x;
  background-size: contain;
  @media (min-width: 576px) {
    grid-area: 1 / 4 / 2 / 6;
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-one.jpg');
  }
  @media (min-width: 992px) {
    grid-area: 1 / 5 / 2 / 7;
  }
}

.te-cover-box-5 {
  grid-area: 1 / 7 / 2 / 8;
}

/* 2段目 */
.te-cover-box-6 {
  grid-area: 2 / 1 / 3 / 2;
}

/* 寄り添うことで */
.te-cover-box-7 {
  grid-area: 4 / 1 / 5 / 2;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-three.jpg');
  background-repeat: repeat-x;
  background-size: contain;
  @media (min-width: 576px) {
    grid-area: 2 / 1 / 3 / 2;
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-two.jpg');
  }
  @media (min-width: 992px) {
    grid-area: 2 / 2 / 3 / 3;
  }
}

.te-cover-box-8 {
  grid-area: 3 / 1 / 4 / 2;
}

/* 学びに集中 */
.te-cover-box-9 {
  grid-area: 5 / 1 / 6 / 2;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-four.jpg');
  background-repeat: repeat-x;
  background-size: contain;
  @media (min-width: 576px) {
    grid-area: 3 / 1 / 4 / 2;
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-three.jpg');
  }
  @media (min-width: 992px) {
    grid-area: 3 / 2 / 4 / 3;
  }
}

/* テックくん */
.te-cover-box-10 {
  grid-area: 1 / 1 / 3 / 4;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-tech-sp.jpg');
  background-repeat: repeat-x;
  background-size: contain;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 1.25rem;
  @media (min-width: 576px) {
    grid-area: 2 / 2 / 4 / 5;
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-tech-pc.jpg');
  }
  @media (min-width: 992px) {
    grid-area: 2 / 3 / 4 / 6;
  }
}

.te-tech-eye-wrap {
  height: 2.125rem;
  background:  linear-gradient(90deg, rgba(255,255,0,1) 0%, rgba(255,255,167,1) 100%);
  border-radius: 1.25rem 1.25rem 0 0;
}

.te-tech-eye {
  top: -0.375rem;
}

/* 熱い心 */
.te-cover-box-11 {
  grid-area: 5 / 2 / 6 / 3;
  background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-four.jpg');
  background-repeat: repeat-x;
  background-size: contain;
  @media (min-width: 576px) {
    grid-area: 2 / 5 / 3 / 6;
    background-image: url('~/assets/images/Te/TeTop/Cover/Consultation/bg-wedget-two.jpg');
  }
  @media (min-width: 992px) {
    grid-area: 2 / 6 / 3 / 7;
  }
}

.te-cover-box-12 {
  grid-area: 2 / 7 / 3 / 8;
}

/* cta */
.te-cover-box-13 {
  grid-area: 3 / 3 / 4 / 4;
  cursor: pointer;
  @media (min-width: 576px) {
    grid-area: 3 / 5 / 4 / 6;
  }
  @media (min-width: 992px) {
    grid-area: 3 / 6 / 4 / 7;
  }
}

.te-squeare-btn {
  position: relative;
  width: 100%;
  padding-top: 100%;
}

.te-cover-cta {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 0, 0.95) 0%,
    rgba(255, 255, 0, 0.78) 100%);
    border-radius: 50%;
    box-shadow: 6px 6px 0 0 rgba(15, 137, 163, 0.3);
}

.te-cover-cta:hover {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 0, 100) 0%,
    rgba(255, 255, 0, 100) 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 rgba(15, 137, 163, 0);
  transition: all 0.5s ease;
}

.te-cover-box-14 {
  grid-area: 3 / 7 / 4 / 8;
}

.te-cover-box-15 {
  grid-area: 4 / 1 / 5 / 2;
}

.te-cover-box-16 {
  @media (min-width: 576px) {
    grid-area: 4 / 1 / 5 / 3;
  }
  @media (min-width: 992px) {
    grid-area: 4 / 2 / 5 / 4;
  }
}

/* 仕事を受注し */
.te-cover-box-17 {
  grid-area: 5 / 3 / 6 / 4;
  @media (min-width: 576px) {
    grid-area: 4 / 3 / 5 / 4;
  }
  @media (min-width: 992px) {
    grid-area: 4 / 4 / 5 / 5;
  }
}

.te-cover-box-18 {
  @media (min-width: 576px) {
    grid-area: 4 / 4 / 5 / 6;
  }
  @media (min-width: 992px) {
    grid-area: 4 / 5 / 5 / 7;
  }
}

.te-cover-box-19 {
  grid-area: 4 / 7 / 5 / 8;
}

/* ミニグリッド */
.te-mini-grid {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.75rem;
}

/* ミニグリッドのアイテム */
.te-mini-grid-1 {
  grid-area: 1 / 1 / 2 / 2;
}

.te-mini-grid-2 {
  grid-area: 2 / 1 / 3 / 2;
}

.te-mini-grid-3 {
  grid-area: 1 / 2 / 2 / 3;
}

.te-mini-grid-4 {
  grid-area: 2 / 2 / 3 / 3;
}

/* ブロック */
.te-bg-cover-mini-item {
  border-radius: 0.75rem;
}

.te-text-min-auto {
  min-width: auto;
}

/* 画像の処理 */
.te-text-vertical-img {
  width: auto;
  height: calc(100% - 2.5rem);
}

</style>
