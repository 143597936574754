<template>
  <section
    id="ctaTest"
    class="co-bg-striped relative py-5"
  >
    <div class="co-container-xs te-counseling-banner-content mb-3 flex flex-col items-center justify-center px-4 md:flex-row">
      <img
        class="te-counseling-illust"
        src="~/assets/images/Te/TeCommon/Banner/Links/counseling-illust.svg"
        alt="無料相談"
        loading="lazy"
      >
      <p class="mx-0 mb-0 md:ml-6 md:mr-5">
        <span>受講について疑問・質問がある方、</span>
        <span class="font-bold text-co-danger-500">まずは話だけ聞いてみたい方</span>
        <span>もぜひご利用ください。</span>
      </p>
    </div>
    <div class="text-center">
      <!-- .bot_open このクラスはBOTCHAN用として追加 -->
      <a
        class="co-btn-counseling bot_open rounded-full"
        href="/counseling/htmlcss-schedule"
        :style="{ gap: '0.5rem' }"
      >
        無料で相談してみる
        <i
          class="fa fa-angle-right ml-2"
          aria-hidden="true"
        />
      </a>
    </div>
  </section>
</template>

<script lang="ts">
import TeCommonBoxShadowBlue from '~/components/Te/TeCommon/Box/TeCommonBoxShadowBlue/TeCommonBoxShadowBlue.vue'

const options = {
  components: {
    TeCommonBoxShadowBlue,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-image-title {
  width: 12.5rem;
  @media (min-width: 768px) {
    width: 14.062rem;
  }
}

.te-counseling-banner-content {
  gap: 0.75rem;
  @media (min-width: 768px) {
    gap: 0;
  }
  .te-counseling-illust {
    max-width: 15rem;
    max-height: 5.5625rem;
    @media (min-width: 992px) {
      max-width: 18.125rem;
      max-height: 6.75rem;
    }
  }
}

.te-counseling-icon-item {
  width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  max-height: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
  cursor: pointer;
  border: 1px solid #33a5cb;
  @media (min-width: 992px) {
    width: 3.375rem;
    max-width: 3.375rem;
    height: 3.375rem;
    max-height: 3.375rem;
    font-size: 1.5rem;
  }
}
</style>
